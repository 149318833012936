'use client'
import useBrowserTabFocus from '@/hooks/useBrowserTabFocus'
import { useInterval } from '@/hooks/useInterval'
import { usePathname, useRouter } from 'next/navigation'
import {
  MouseEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

type FloatingChatContextResult = {
  isOpen: boolean
  open: () => void
  close: () => Promise<void>
  toggle: () => void
}

const FloatingChatContext = createContext({} as FloatingChatContextResult)

export const useFloatingChat = () => useContext(FloatingChatContext)

type FloatingChatProps = {
  children: ReactNode
}

export const FLOATING_CHAT_WINDOW_ID = 'floating-chat-window'

export const FLOATING_CHAT_LIST_ID = 'floating-chat-list'

export const FLOATING_CHAT_LIST_CONTENT_ID = 'floating-chat-list-content'

export const FLOATING_CHAT_CONVERSATION_ID = 'floating-chat-conversation'

export const FLOATING_CHAT_HEADING_ID = 'floating-chat-heading'

const getConversationElements = () => {
  const floatingChatWindow = document.getElementById(FLOATING_CHAT_WINDOW_ID)

  const floatingChatList = document.getElementById(FLOATING_CHAT_LIST_ID)

  const floatingChatListContent = document.getElementById(
    FLOATING_CHAT_LIST_CONTENT_ID
  )

  return { floatingChatWindow, floatingChatList, floatingChatListContent }
}

export const showConversation = (e: MouseEvent<HTMLButtonElement>) => {
  const { floatingChatWindow } = getConversationElements()

  const chatListItem = e.currentTarget.closest('li[role="treeitem"]')
  const chatTree = chatListItem?.closest('menu[role="tree"]')

  if (!chatListItem || !chatTree) return

  chatTree.setAttribute('data-has-expanded-conversation', 'true')
  chatListItem.setAttribute('aria-expanded', 'true')

  floatingChatWindow?.scroll({
    top: floatingChatWindow.scrollHeight,
  })

  floatingChatWindow?.scroll({
    left: floatingChatWindow.scrollWidth,
    behavior: 'smooth',
  })
}

export const goBackFromConversation = (e: MouseEvent<HTMLButtonElement>) => {
  const { floatingChatWindow } = getConversationElements()

  const chatListItem = e.currentTarget.closest('li[role="treeitem"]')
  const chatTree = chatListItem?.closest('menu[role="tree"]')

  if (!chatListItem || !chatTree) return

  chatTree.setAttribute('data-has-expanded-conversation', 'false')
  chatListItem.setAttribute('aria-expanded', 'false')

  floatingChatWindow?.scroll({
    top: 0,
  })

  floatingChatWindow?.scroll({
    left: 0,
    behavior: 'smooth',
  })
}

const FloatingChat = ({ children }: FloatingChatProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { isTabFocused } = useBrowserTabFocus()

  const open = useCallback(() => setIsOpen(true), [setIsOpen])

  const close = useCallback(async () => {
    const { floatingChatWindow, floatingChatList } = getConversationElements()

    setIsOpen(false)

    setTimeout(() => {
      floatingChatWindow?.scroll({
        left: 0,
        top: 0,
      })

      floatingChatList?.setAttribute('data-has-expanded-conversation', 'false')

      floatingChatList
        ?.querySelectorAll('li[role="treeitem"][aria-expanded="true"]')
        .forEach((item) => {
          item.setAttribute('aria-expanded', 'false')
        }, [])
    }, 500)
  }, [setIsOpen])

  const toggle = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen])

  const pathname = usePathname()
  const isChatPage = pathname.includes('/chat')

  const router = useRouter()

  const handleInterval = useCallback(() => {
    if (isChatPage) return

    if (isTabFocused && isOpen) {
      router.refresh()
    }
  }, [isChatPage, router, isTabFocused, isOpen])

  useInterval(handleInterval, 5000)

  useEffect(() => {
    const { floatingChatWindow } = getConversationElements()

    floatingChatWindow?.scroll({
      left: 0,
      top: 0,
    })
  }, [])

  if (isChatPage) {
    return null
  }

  return (
    <FloatingChatContext.Provider value={{ isOpen, open, close, toggle }}>
      {children}
    </FloatingChatContext.Provider>
  )
}

export default FloatingChat
