import classNames from 'classnames'
import {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ComponentType,
  ElementType,
  ForwardedRef,
  forwardRef,
} from 'react'

import styles from './Box.module.css'

export type AsProp = ElementType | ComponentType

export type AsDynamicProps<
  As extends AsProp,
  CurrentComponentProps = unknown,
> = {
  as?: As
} & Omit<ComponentPropsWithoutRef<As>, keyof CurrentComponentProps>

export type AsDynamicPropsWithRef<
  As extends AsProp,
  CurrentComponentProps = unknown,
> = {
  as?: As
} & Omit<ComponentPropsWithRef<As>, keyof CurrentComponentProps>

export type BoxProps<As extends AsProp = 'div'> = AsDynamicProps<As> & {
  mb?: string | number
}

const Box = <As extends AsProp = 'div'>(
  { as, mb, className, ...props }: BoxProps<As>,
  ref: ForwardedRef<HTMLElement>
) => {
  const Element = as ?? 'div'

  return (
    // @ts-expect-error
    <Element
      ref={ref}
      className={classNames(className, {
        [styles.mb]: mb,
      })}
      style={{
        ...props.style,
        '--mb': typeof mb === 'number' ? `${mb}px` : mb,
      }}
      {...props}
    />
  )
}

export type BoxComponent<As extends AsProp = 'div'> = ComponentType<
  BoxProps<As>
>

export default forwardRef(Box)
