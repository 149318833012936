'use client'
import { BoxProps } from '@/components/atoms/Box/Box'
import classNames from 'classnames'

import { markMultipleMessagesAsRead } from '@/components/organisms/chat/ChatMessage/actions'
import { showConversation } from '@/components/organisms/chat/FloatingChat/FloatingChat'
import { ConversationType } from '@/components/organisms/chat/chat.types'
import { EventFor } from '@/utils/types.utils'

import styles from './FloatingChatWindow.module.css'

type FloatingChatWindowListItemButtonProps = BoxProps<'button'> & {
  conversationId?: number
  conversationType?: ConversationType
}

const FloatingChatWindowListItemButton = ({
  className,
  conversationId,
  conversationType,
  children,
  ...props
}: FloatingChatWindowListItemButtonProps) => {
  const handleListItemClick = async (e: EventFor<'button', 'onClick'>) => {
    showConversation(e)

    try {
      if (!conversationType || !conversationId) {
        throw new Error('Conversation type or id is missing')
      }

      await markMultipleMessagesAsRead(conversationType, conversationId)
    } catch (e) {
      console.error(`Error marking messages as read: ${e}`, {
        conversationType,
        conversationId,
      })
    }
  }

  return (
    <button
      className={classNames(className, styles.chatListItemButton)}
      {...props}
      onClick={handleListItemClick}
    >
      {children}
    </button>
  )
}

export default FloatingChatWindowListItemButton
