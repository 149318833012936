'use client'

import { BoxProps } from '@/components/atoms/Box/Box'
import EmptyContent from '@/components/atoms/EmptyContent/EmptyContent'
import { getDifferenceOrFormattedDate } from '@/utils/dates'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

type TimeProps = BoxProps<'time'> & {
  date: Date | null | string
  format?: string
}

const Time = ({
  children,
  date,
  format = 'DD MMM, YYYY',
  ...props
}: TimeProps) => {
  const [formattedDate, setFormattedDate] = useState<string>()
  const [datetimeDate, setDatetimeDate] = useState<string>()

  useEffect(() => {
    if (!date) {
      setFormattedDate(undefined)
    } else {
      const parsedDate = dayjs(date)

      setDatetimeDate(parsedDate.toISOString())
      setFormattedDate(getDifferenceOrFormattedDate(parsedDate, format))
    }
  }, [date, format])

  if (!formattedDate) {
    return <EmptyContent />
  }

  return (
    <time dateTime={datetimeDate} {...props}>
      {children ?? formattedDate}
    </time>
  )
}

export default Time
