'use client'
import { usePathname } from 'next/navigation'

import IconButton, {
  IconButtonProps,
} from '@/components/atoms/Button/IconButton'
import {
  FLOATING_CHAT_WINDOW_ID,
  useFloatingChat,
} from '@/components/organisms/chat/FloatingChat/FloatingChat'

import styles from './FloatingChatButton.module.css'

type FloatingChatButtonProps = Partial<IconButtonProps> & {
  icon: IconButtonProps['icon']
}

const FloatingChatIconButton = ({ ...props }: FloatingChatButtonProps) => {
  const pathname = usePathname()
  const isChatPage = pathname.includes('/chat')

  const { toggle } = useFloatingChat()

  if (isChatPage) {
    return null
  }

  return (
    <IconButton
      onClick={toggle}
      variant="unstyled"
      className={styles.container}
      aria-controls={FLOATING_CHAT_WINDOW_ID}
      aria-haspopup="dialog"
      data-full-width
      {...props}
      aria-label="Toggle Chat Window"
    />
  )
}

export default FloatingChatIconButton
