import styles from '@/app/styles.json'

import Icon, { IconProps } from './Icon'

const QuitIcon = ({ color = 'currentColor', ...props }: IconProps) => {
  return (
    <Icon {...props} fill="none">
      <path
        d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
        fill={color ?? styles.color['neutral-0']}
      />
    </Icon>
  )
}

export default QuitIcon
