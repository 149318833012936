'use client'
import IconButton, {
  IconButtonProps,
} from '@/components/atoms/Button/IconButton'
import CloseIcon from '@/components/icons/CloseIcon'
import {
  FLOATING_CHAT_WINDOW_ID,
  useFloatingChat,
} from '@/components/organisms/chat/FloatingChat/FloatingChat'
import classNames from 'classnames'

import styles from './FloatingChatWindow.module.css'

const FloatingChatWindowCloseButton = ({
  className,
  ...props
}: Partial<IconButtonProps>) => {
  const { close } = useFloatingChat()

  return (
    <IconButton
      className={classNames(className, styles.closeButton)}
      variant="unstyled"
      icon={<CloseIcon size={'1.25rem'} />}
      aria-label="Close Chat Window"
      aria-controls={FLOATING_CHAT_WINDOW_ID}
      onClick={close}
      {...props}
    />
  )
}

export default FloatingChatWindowCloseButton
