'use client'
import { useCallback, useEffect, useRef } from 'react'

import { BoxProps } from '@/components/atoms/Box/Box'

import styles from './styles.module.css'

export const TOP_BAR_HEADER_ID = 'topbarheader'

const TopBarHeader = ({ children, ...props }: BoxProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleGradient = useCallback(() => {
    const panelLayoutElement = document.querySelector(`.${styles.panelLayout}`)

    if (!ref.current || !panelLayoutElement) {
      return
    }

    const hasScroll =
      document.documentElement.scrollTop > 0 || document.body.scrollTop > 0

    if (hasScroll) {
      panelLayoutElement.classList.add(styles.hasScroll)

      return
    }

    panelLayoutElement.classList.remove(styles.hasScroll)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleGradient)
    window.addEventListener('resize', handleGradient)

    return () => {
      window.removeEventListener('scroll', handleGradient)
      window.removeEventListener('resize', handleGradient)
    }
  }, [handleGradient])

  return (
    <header
      ref={ref}
      id={TOP_BAR_HEADER_ID}
      className={styles.topBarHeader}
      {...props}
    >
      {children}
    </header>
  )
}

export default TopBarHeader
