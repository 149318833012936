import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isToday } from 'date-fns'
dayjs.extend(utc)

const SECONDS_IN_A_DAY = 86400
const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * 365

export const getDifferenceOrFormattedDate = (
  dateToCalc: Dayjs | Date | string | null,
  format: string
) => {
  if (!dateToCalc) {
    return '---'
  }

  const date = dayjs(dateToCalc)
  const daysDiff = date.diff(dayjs(), 'day', true)

  const isYesterday = daysDiff > -1 && daysDiff < 0 && !isToday(date.toDate())

  if (isYesterday) {
    return 'Yesterday'
  }

  const isDateToday = isToday(date.toDate()) || (daysDiff >= 0 && daysDiff < 1)

  if (isDateToday) {
    return 'Today'
  }

  return date.format(format)
}

export const getTimeDifferenceInSeconds = (
  startDateTime: Date | string | null,
  endDateTime: Date | string | null
) => {
  if (!startDateTime || !endDateTime) {
    return 0
  }

  const start = dayjs(startDateTime)
  const end = dayjs(endDateTime)

  return end.diff(start, 'seconds')
}

export const secondsToHoursAndMinutes = (seconds: number) => {
  if (!seconds) {
    return '---'
  }

  const totalMinutes = Math.floor(seconds / 60)

  const hours = Math.floor(totalMinutes / 60)
  const remainingMinutes = Math.floor(totalMinutes % 60)

  let stringfied = ''

  if (hours) {
    stringfied += `${hours} hour${hours > 1 ? 's' : ''} `
  }

  if (remainingMinutes) {
    stringfied += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
  }

  return stringfied
}

export const getMessageTimeIndicator = (
  messageDate: Date | string | null,
  isConversationListItem = false
) => {
  if (!messageDate) {
    return ''
  }

  const now = dayjs()

  const secondsSinceMessageSent = getTimeDifferenceInSeconds(
    dayjs(messageDate).toDate(),
    now.toDate()
  )
  const totalMinutes = Math.floor(secondsSinceMessageSent / 60)

  if (totalMinutes === 0) {
    return 'now'
  }

  if (totalMinutes <= 10) {
    return `${totalMinutes} min ago`
  }

  let formatString = 'hh:mm a'

  if (secondsSinceMessageSent > SECONDS_IN_A_DAY) {
    if (isConversationListItem) {
      formatString = 'DD MMM'
    } else {
      formatString += ', DD MMM'
    }
  }

  if (secondsSinceMessageSent > SECONDS_IN_A_YEAR) {
    formatString += ', YYYY'
  }

  return dayjs(messageDate).format(formatString)
}

export const formatTimeForBackend = (date: string, time: string): string => {
  return dayjs(`${date}T${time}`).utc().format('HH:mm')
}

// Merges date and time into a single Date value in UTC.
// This function is used for Events. The Event model has date and time as two different attributes,
// what prevents us to correctly parse timezones.
// To avoid big changes in the database model, we decided to implement this function to handle UTC values using the existing model.

// TODO: Change Event model to have full startDate and endDates values rather than separate time_start and time_end.

export const mergeTimeDateUTC = (date: Date, time: Date) => {
  const utcDate = dayjs(time).utc()

  return dayjs(date)
    .utc()
    .set('hours', utcDate.get('hour'))
    .set('minutes', utcDate.get('minute'))
    .toDate()
}
