'use client'

import LoadingSpinner from '@/components/atoms/LoadingSpinner/LoadingSpinner'
import QuitIcon from '@/components/icons/QuitIcon'
import cn from 'classnames'
import { useCallback, useState } from 'react'

import styles from './LogoutButton.module.css'

type LogoutButtonProps = {
  className?: string
}

export default function LogoutButton({ className }: LogoutButtonProps) {
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await fetch('/api/logout')

      if (response.ok) {
        setIsLoading(true)
        window.location.href = '/'
      } else {
        throw new Error('Failed to log out')
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <button
      className={cn(styles.button, className)}
      onClick={handleLogout}
      disabled={isLoading}
      aria-label="Logout"
      aria-busy={isLoading}
    >
      {isLoading ? (
        <LoadingSpinner className={styles.loadingSpinner} />
      ) : (
        <QuitIcon />
      )}

      <span data-nav-link-label>
        {isLoading ? 'Logging out...' : 'Log out'}
      </span>
    </button>
  )
}
