export const getIsSomeHrefSegmentInPathname = (
  href: string,
  pathname: string
) => {
  const hrefSegments = href.split('/').filter(Boolean)
  const pathnameSegments = pathname.split('/').filter(Boolean)

  if (hrefSegments[0] !== pathnameSegments[0]) {
    return false
  }

  const isAnySegmentInHref = hrefSegments.some(
    (hrefSegment) =>
      pathnameSegments.includes(hrefSegment) && isNaN(Number(hrefSegment))
  )

  return isAnySegmentInHref
}
