'use client'
import { BoxProps } from '@/components/atoms/Box/Box'
import {
  FLOATING_CHAT_HEADING_ID,
  FLOATING_CHAT_WINDOW_ID,
  useFloatingChat,
} from '@/components/organisms/chat/FloatingChat/FloatingChat'
import classNames from 'classnames'
import { CSSProperties, useEffect, useState } from 'react'
import { getScrollbarWidth } from '@/utils/scrollbar.utils'

import styles from './FloatingChatWindow.module.css'

type FloatingChatWindowContainerProps = BoxProps<'section'>

const FloatingChatWindowContainer = ({
  children,
  className,
  ...props
}: FloatingChatWindowContainerProps) => {
  const { isOpen } = useFloatingChat()
  const [scrollbarWidth, setScrollbarWidth] = useState('0px')

  useEffect(() => {
    setScrollbarWidth(getScrollbarWidth())
  }, [])

  const customProperties = {
    '--scrollbar-width': scrollbarWidth,
  } as CSSProperties

  return (
    <section
      role="dialog"
      className={classNames(styles.floatingChatWindow)}
      aria-labelledby={FLOATING_CHAT_HEADING_ID}
      aria-hidden={!isOpen}
      id={FLOATING_CHAT_WINDOW_ID}
      {...props}
      style={customProperties}
    >
      {children}
    </section>
  )
}

export default FloatingChatWindowContainer
