'use client'
import Tippy, { TippyProps } from '@tippyjs/react'
import classNames from 'classnames'
import { ReactNode } from 'react'

import 'tippy.js/dist/tippy.css'

import styles from './Tooltip.module.css'

export type TooltipProps = Omit<TippyProps, 'content'> & {
  content?: ReactNode
}

const Tooltip = ({ children, className, content, ...props }: TooltipProps) => {
  return (
    <Tippy
      className={classNames(className, styles.toolTip)}
      arrow
      content={content}
      {...props}
    >
      {children}
    </Tippy>
  )
}

export default Tooltip
