'use client'
import { cloneElement, forwardRef, useRef } from 'react'

import Tooltip, { TooltipProps } from '@/components/atoms/Tooltip/Tooltip'
import classNames from 'classnames'
import Button, { ButtonProps } from './Button'

import styles from './IconButton.module.css'

export type IconButtonProps = Omit<ButtonProps, 'children'> & {
  'aria-label': string
  icon: JSX.Element
  shouldShowTooltip?: boolean
  tooltipContent?: TooltipProps['content']
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      'aria-label': ariaLabel,
      icon,
      shouldShowTooltip = true,
      tooltipContent,
      variant = 'ghost',
      ...props
    },
    forwardedRef
  ) => {
    const localRef = useRef<HTMLButtonElement | null>(null)

    return (
      <Tooltip
        disabled={!shouldShowTooltip}
        content={tooltipContent ?? ariaLabel}
      >
        <Button
          className={classNames(className, styles.iconButton)}
          ref={(e) => {
            const ref = e as HTMLButtonElement | null

            localRef.current = ref

            if (typeof forwardedRef === 'function') {
              forwardedRef(localRef.current)
            } else if (forwardedRef) {
              forwardedRef.current = localRef.current
            }
          }}
          aria-label={ariaLabel}
          variant={variant}
          {...props}
        >
          {cloneElement(icon)}
        </Button>
      </Tooltip>
    )
  }
)

IconButton.displayName = 'IconButton'

export default IconButton
