/**
 * Get the width of the scrollbar.
 *
 * This function calculates the width of the scrollbar by subtracting the width of the client area of the document
 * (document.documentElement.clientWidth) from the width of the inner window (window.innerWidth).
 *
 * If this calculation fails (for example, if it's run in a context where `window` or `document` is not available),
 * it fails silently and returns a default scrollbar width of 16px.
 *
 * @returns {string} The width of the scrollbar in pixels.
 */
export const getScrollbarWidth = (): string => {
  let scrollWidth = 15 // default value

  try {
    scrollWidth = window.innerWidth - document.documentElement.clientWidth
  } catch (e) {
    // fail silently
  }

  return `${scrollWidth}px`
}
