import classNames from 'classnames'
import React, { ForwardedRef, ReactElement, Ref, forwardRef } from 'react'

import Box, { AsProp, BoxProps } from '@/components/atoms/Box/Box'
import LoadingSpinner from '@/components/atoms/LoadingSpinner/LoadingSpinner'

import styles from './Button.module.css'

const ButtonSizes = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
} as const

type ButtonSize = keyof typeof ButtonSizes

const ButtonColors = {
  primary: styles.primary,
  secondary: styles.secondary,
} as const

type ButtonColor = keyof typeof ButtonColors

const ButtonVariants = {
  filled: styles.filled,
  outlined: styles.outlined,
  link: styles.link,
  ghost: styles.ghost,
  unstyled: '',
} as const

type ButtonVariant = keyof typeof ButtonVariants

const ButtonPositioning = {
  relative: styles.relative,
  absolute: styles.absolute,
} as const

type ButtonPosition = keyof typeof ButtonPositioning

const ButtonDisplaying = {
  flex: styles.flex,
  inlineFlex: styles.inlineFlex,
}
type ButtonDisplay = keyof typeof ButtonDisplaying

const ButtonBackgroundStrategy = {
  default: '',
  inherit: styles.bgInherit,
}

type ButtonBackground = keyof typeof ButtonBackgroundStrategy

export type ButtonProps<As extends AsProp = 'button'> = BoxProps<As> & {
  size?: ButtonSize
  color?: ButtonColor
  variant?: ButtonVariant
  icon?: ReactElement
  position?: ButtonPosition
  isLoading?: boolean
  display?: ButtonDisplay
  ref?: Ref<HTMLButtonElement>
  background?: ButtonBackground
}

export const BaseButton = <As extends AsProp = 'button'>(
  {
    children,
    className,
    size = 'large',
    color = 'primary',
    variant = 'filled',
    disabled,
    icon,
    isLoading,
    position = 'relative',
    display = 'inlineFlex',
    'aria-label': ariaLabel,
    as,
    background = 'default',
    ...props
  }: ButtonProps<As>,
  ref: ForwardedRef<HTMLElement>
) => {
  const AsPropValue = as ?? 'button'

  return (
    <Box
      ref={ref}
      as={AsPropValue}
      type={'button'}
      className={
        variant === 'unstyled'
          ? className
          : classNames(
              className,
              styles.button,
              ButtonBackgroundStrategy[background],
              ButtonDisplaying[display],
              ButtonVariants[variant],
              ButtonPositioning[position],
              ButtonSizes[size],
              ButtonColors[color],
              disabled ? styles.disabled : ''
            )
      }
      disabled={disabled || isLoading}
      aria-busy={isLoading}
      {...props}
    >
      <span className={styles.text}>
        {!!icon && React.cloneElement(icon, { size: 20 })}
        {children}
      </span>

      <LoadingSpinner
        aria-hidden={!isLoading}
        className={styles.loadingSpinner}
      />
    </Box>
  )
}

export default forwardRef<HTMLElement, ButtonProps<AsProp>>(
  BaseButton
) as typeof BaseButton<AsProp>
