import { BoxProps } from '@/components/atoms/Box/Box'
import classNames from 'classnames'

import styles from './EmptyContent.module.css'

const EmptyContent = ({
  children = '--',
  className,
  ...props
}: BoxProps<'span'>) => (
  <span className={classNames(className, styles.emptyContent)} {...props}>
    {children}
  </span>
)

export default EmptyContent
