import Icon, { IconProps } from './Icon'

const ErrorIcon = ({ color = 'currentColor', ...props }: IconProps) => {
  return (
    <Icon {...props} fill="none">
      <path
        d="M3 8L8 3H16L21 8V16L16 21H7.90909L3 16V8Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M9 9L15 15" stroke={color} strokeWidth="1.5" />
      <path d="M9 15L15 9" stroke={color} strokeWidth="1.5" />
    </Icon>
  )
}

export default ErrorIcon
