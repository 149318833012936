'use client'
import { UserJWTTokenPayload } from '@/lib/auth/payload'
import Script from 'next/script'
import { useEffect, useState } from 'react'

type HeapAnalyticsProps = {
  appId?: string
  user?: UserJWTTokenPayload['user']
}

const HeapAnalytics = ({ appId, user }: HeapAnalyticsProps) => {
  const [heapReady, setHeapReady] = useState(false)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (initialized) {
      return
    }

    if (heapReady && user?.email) {
      window.heap.identify(user.email)

      window.heap.addUserProperties({
        environment: process.env.NODE_ENV,
        email: user.email,
        o2x_id: user.o2x_id,
        first_name: user.first_name,
        last_name: user.last_name,
        is_staff: user.is_staff,
        is_superuser: user.is_superuser,
        organization_name: user.organizations_organization.name,
      })

      console.info(`Heap Analytics initialized`)
      setInitialized(true)
    }
  }, [heapReady, user, initialized])

  if (!appId) {
    console.warn('Heap Analytics appId is not provided')
    return null
  }

  return (
    <Script
      id="heap-analytics"
      strategy="afterInteractive"
      onReady={() => {
        setHeapReady(true)
      }}
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
        heap.load("${appId}"); 
        `,
      }}
    />
  )
}

export default HeapAnalytics
