'use client'
import { BoxProps } from '@/components/atoms/Box/Box'
import classNames from 'classnames'

import styles from './FloatingChatWindow.module.css'

const FloatingChatWindowListItem = ({
  className,
  children,
  ...props
}: BoxProps<'li'>) => {
  return (
    <li className={classNames(className, styles.chatListItem)} {...props}>
      {children}
    </li>
  )
}

export default FloatingChatWindowListItem
