'use client'
import IconButton, {
  IconButtonProps,
} from '@/components/atoms/Button/IconButton'
import ArrowBackIcon from '@/components/icons/ArrowBackIcon'
import { goBackFromConversation } from '@/components/organisms/chat/FloatingChat/FloatingChat'
import classNames from 'classnames'

import styles from './FloatingChatWindow.module.css'

const FloatingChatWindowConversationBackButton = ({
  className,
  ...props
}: Partial<IconButtonProps>) => {
  return (
    <IconButton
      variant="ghost"
      icon={<ArrowBackIcon />}
      className={classNames(className, styles.backButton)}
      aria-label="Back to Chat List"
      onClick={goBackFromConversation}
      {...props}
    />
  )
}

export default FloatingChatWindowConversationBackButton
