'use client'

import { LinkProps } from 'next/link'
import { usePathname } from 'next/navigation'
import { ReactNode } from 'react'

import { BoxProps } from '@/components/atoms/Box/Box'
import LinkWithEagerPrefetching from '@/components/atoms/Link/LinkWithEagerPrefetching'
import { getIsSomeHrefSegmentInPathname } from '@/lib/routing/getIsSomeHrefSegmentInPathname'

type SidebarLinkProps = LinkProps &
  BoxProps<'a'> & {
    children: ReactNode
  }

const SidebarLink = ({ children, href, ...props }: SidebarLinkProps) => {
  const pathname = usePathname()

  const isSomeHrefSegmentInPathname = getIsSomeHrefSegmentInPathname(
    href.toString(),
    pathname
  )

  return (
    <li>
      <LinkWithEagerPrefetching
        href={href}
        aria-current={isSomeHrefSegmentInPathname ? 'page' : 'false'}
        {...props}
      >
        {children}
      </LinkWithEagerPrefetching>
    </li>
  )
}

export const SidebarLinksList = ({ children }: { children: ReactNode }) => (
  <ul>{children}</ul>
)

export default Object.assign(SidebarLink)
