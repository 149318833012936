import styles from '@/app/styles.json'

import Icon, { IconProps } from './Icon'

const ArrowBackIcon = ({ color = 'currentColor', ...props }: IconProps) => {
  return (
    <Icon fill="none" {...props}>
      <g clipPath="url(#clip0_1_698)">
        <path
          d="M7.828 11.0002H20V13.0002H7.828L13.192 18.3642L11.778 19.7782L4 12.0002L11.778 4.22217L13.192 5.63617L7.828 11.0002Z"
          fill={color ?? styles.color['neutral-0']}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_698">
          <rect
            width={props.size ?? 24}
            height={props.size ?? 24}
            fill={color ?? styles.color['neutral-0']}
          />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default ArrowBackIcon
