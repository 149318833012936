'use client'
import { UserJWTTokenPayload } from '@/lib/auth/payload'
import { ReactNode, createContext, useContext } from 'react'

// createServerContext was not working for some god damn reason
export const UserContext = createContext(
  // 'UserContext',
  {} as UserJWTTokenPayload
)

type UserContextProviderProps = {
  children: ReactNode
  payload: UserJWTTokenPayload
}

export const UserContextProvider = ({
  payload,
  children,
}: UserContextProviderProps) => {
  return <UserContext.Provider value={payload}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)
