'use client'
import { updateSidebarUISettingsAction } from '@/app/(panel)/actions'
import ArrowBackIcon from '@/components/icons/ArrowBackIcon'
import { UISettings } from '@/lib/settings/UI'
import classNames from 'classnames'
import { MouseEventHandler, useCallback } from 'react'

import styles from './styles.module.css'

type SidebarCollapseButtonProps = {
  initialState: UISettings['sidebar']
}

const SidebarCollapseButton = ({
  initialState,
}: SidebarCollapseButtonProps) => {
  const toggleSidebar: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      const panelLayout = document.querySelector(`.${styles.panelLayout}`)

      if (!panelLayout) {
        return
      }

      const sidebarState = panelLayout.getAttribute(
        'sidebar-state'
      ) as UISettings['sidebar']

      if (sidebarState === 'expanded') {
        const upcomingSidebarState = 'collapsed' satisfies UISettings['sidebar']

        try {
          updateSidebarUISettingsAction(upcomingSidebarState)
        } catch (error) {
          console.error(error)
        }

        panelLayout.setAttribute('sidebar-state', upcomingSidebarState)
        e.currentTarget.setAttribute('aria-expanded', 'false')

        return
      }

      if (sidebarState === 'collapsed') {
        const upcomingSidebarState = 'expanded' satisfies UISettings['sidebar']

        try {
          updateSidebarUISettingsAction(upcomingSidebarState)
        } catch (error) {
          console.error(error)
        }

        panelLayout.setAttribute('sidebar-state', upcomingSidebarState)
        e.currentTarget.setAttribute('aria-expanded', 'true')

        return
      }
    },
    []
  )

  return (
    <button
      type="button"
      className={classNames(styles.sidebarCollapseButton)}
      onClick={toggleSidebar}
      aria-label="Toggle Sidebar"
      aria-controls="sidebar"
      aria-expanded={initialState === 'expanded' ? 'true' : 'false'}
    >
      <ArrowBackIcon />
    </button>
  )
}

export default SidebarCollapseButton
